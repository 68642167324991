import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BgBlue from "../images/bg.inline.svg"


import { Row, Card, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Accordion from 'react-bootstrap/Accordion'

import Layout from "../components/layout"

function HomepageTemplate({ data, pageContext: { lang, currentPath } }) {


  useEffect(() => {
    var previousScroll = 0;
    var animationStarted = false;
    window.addEventListener('scroll', function (event) {
      if (window.scrollY >= document.getElementById('safety').offsetTop - 380) {
        document.getElementById('safety').classList.toggle('animate-safety')
      }
      if (animationStarted) {
        return false
      }
      var scroll = window.scrollY
      var top = document.getElementById('coinsgrow').offsetTop
      var shouldAnimate = scroll >= top - 180
      var shouldStart = shouldAnimate && previousScroll < top - 180
      var coins = document.querySelectorAll('#first-coin,#second-coin,#third-coin,#left0-coin,#left1-coin,#right0-coin,#right1-coin,#right2-coin')
      if (shouldAnimate) {
        document.getElementById('coinsgrow').classList.toggle('animate-grow')
      }
      if (shouldStart) {
        for (var i = 0; i < coins.length; i++) {
          coins[i].classList.remove('remove-coins')
        }
        var timers = [
          setTimeout(function () {
            document.getElementById('first-coin').classList.add('fade-in-coins')
          }, 150),
          setTimeout(function () {
            document.getElementById('second-coin').classList.add('fade-in-coins')
          }, 300),
          setTimeout(function () {
            document.getElementById('third-coin').classList.add('fade-in-coins')
          }, 450),
          setTimeout(function () {
            document.getElementById('left0-coin').classList.add('fade-in-coins')
          }, 600),
          setTimeout(function () {
            document.getElementById('left1-coin').classList.add('fade-in-coins')
          }, 750),
          setTimeout(function () {
            document.getElementById('right0-coin').classList.add('fade-in-coins')
          }, 900),
          setTimeout(function () {
            document.getElementById('right1-coin').classList.add('fade-in-coins')
          }, 1050),
          setTimeout(function () {
            document.getElementById('right2-coin').classList.add('fade-in-coins')
          }, 1200),
        ];
        animationStarted = true
      }
      previousScroll = scroll
    })
  

      document.getElementById('depositevalue').addEventListener('change, keypress', (e) => {
        var txt = String.fromCharCode(e.which)
        var depositevalue = document.getElementById("depositevalue").value
        document.getElementById('deposite').innerHTML=depositevalue

        var monthval = document.getElementById('monthvalue').value

        if (monthval == 12) {
          var price = depositevalue * 1.1
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 10
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 24) {
          var price = depositevalue * 1.2
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 20
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 36) {
          var price = depositevalue * 1.4
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 40
          document.getElementById('bonus').innerHTML=bonus
        };
        if (e.keyCode === 190) {
          e.preventDefault()
        }
        if (!txt.match(/[A-Za-z0-9&. ]/)) {
          return false
        }
      })
      function ForNumbers(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (
          charCode >= 48 && charCode <= 57 ||
          charCode >= 96 && charCode <= 105 ||
          charCode == 8 ||
          charCode == 9 ||
          charCode == 13 ||
          charCode >= 35 && charCode <= 46
        ) {
          if (parseInt(this.value + String.fromCharCode(charCode), 10) <= 9999999)
            return true;
        }
        evt.preventDefault();
        evt.stopPropagation();
        return false;
      }
      document.getElementById('depositevalue').addEventListener('change, keypress', ForNumbers, false)
      document.getElementById('depositevalue').addEventListener('change', (e) => {
        var monthval = document.getElementById("monthvalue").value
        document.getElementById('month').innerHTML=monthval

        var depositevalue = document.getElementById("depositevalue").value
        document.getElementById('deposite').innerHTML=depositevalue

        if (monthval == 12) {
          var price = depositevalue * 1.1
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 10
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 24) {
          var price = depositevalue * 1.2
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 20
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 36) {
          var price = depositevalue * 1.4
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 40
          document.getElementById('bonus').innerHTML=bonus
        }
      })
      document.getElementById('monthvalue').addEventListener('change, keypress', ForNumbers, false)
      document.getElementById('monthvalue').addEventListener('change', (e) => {
        var monthval = document.getElementById('monthvalue').value
        document.getElementById('month').innerHTML=monthval
        
        var depositevalue = document.getElementById("depositevalue").value
        document.getElementById('deposite').innerHTML=depositevalue
        
        if (monthval == 12) {
          var price = depositevalue * 1.1
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 10
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 24) {
          var price = depositevalue * 1.2
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 20
          document.getElementById('bonus').innerHTML=bonus
        };

        if (monthval == 36) {
          var price = depositevalue * 1.4
          document.getElementById('totalamount').innerHTML=(price.toFixed(0))
          var bonus = 40
          document.getElementById('bonus').innerHTML=bonus
        }
    })

  }, [])
  return (

    
    <Layout wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">


      <Row className="hero">
        <div className="container">
          <div className="col-lg-12">
            <div className="hero__text-holder">
              <h1 className="hero__title hero__title--boxed" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_title }} />
              <h2 className="hero__subtitle" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.home_desc }} />
            </div>
            <div className="grow-form">
              <form>
                <div className="form-one">
                  <p>{data.wordpressPage.acf.amount_to_deposit}</p>
                  <label className="dag-text"><input id="depositevalue" type="number" name="quantity" min="1" max="9999999" placeholder="1000"/></label>
                </div>
                <div className="form-one">
                  <p>{data.wordpressPage.acf.duration}</p>
                  <select id="monthvalue">
                    <option value="12">12 {data.wordpressPage.acf.months}</option>
                    <option value="24">24 {data.wordpressPage.acf.months}</option>
                    <option value="36">36 {data.wordpressPage.acf.months}</option>
                  </select>
                </div>
                <div className="results">
                  <div className="one-row">
                    <div className="first-a">{data.wordpressPage.acf.initial_deposit}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b"><span id="deposite">1000</span> DAG</div>
                  </div>
                  <div className="one-row">
                    <div className="first-a">{data.wordpressPage.acf.period}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b"><span id="month">12</span> {data.wordpressPage.acf.months}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-a">{data.wordpressPage.acf.bonus_rate}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b"><span id="bonus">10</span>%</div>
                  </div>
                </div>
                <div className="more">
                  <div className="one-row">
                    <div className="first-a">{data.wordpressPage.acf.youll_get}</div>
                  </div>
                  <div className="one-row">
                    <div className="first-b"><span id="totalamount">1100</span> DAG</div>
                  </div>
                </div>
                <div className="final">
                  <a href="https://app.grow.dagcoin.org/sign-up">{data.wordpressPage.acf.start_growing}</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Row>
      <Row className="partone pbt260">
        <div className="container">
          <div className="col-lg-12">
            <h3>{data.wordpressPage.acf.grow_tile_duplicate}</h3>
            <h2>{data.wordpressPage.acf.how_it_works}</h2>
            <ul>
              <li>
                <Img fluid={data.imageone.childImageSharp.fluid} />
                <p className="title-list">{data.wordpressPage.acf.how_1_title}</p>
                <p>{data.wordpressPage.acf.how_1_desc}</p>
              </li>
              <li>
                <Img fluid={data.imagetwo.childImageSharp.fluid} />
                <p className="title-list">{data.wordpressPage.acf.how_2_title}</p>
                <p>{data.wordpressPage.acf.how_2_desc}</p>
              </li>
              <li>
                <Img fluid={data.imagethree.childImageSharp.fluid} />
                <p className="title-list">{data.wordpressPage.acf.how_3_title}</p>
                <p>{data.wordpressPage.acf.how_3_desc}</p>
              </li>
            </ul>
            <a className="sign-up-btn" href="https://app.grow.dagcoin.org/sign-up">{data.wordpressPage.acf.start_now}</a>
          </div>
        </div>
      </Row>
      <section id="coinsgrow" className="sections animate-grow">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <div className="content-img order1">
                <div className="coins-stack">
                  <div className="coins-wrapper">
                    <img id="left0-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="left1-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="default-coin" src="https://downloads.dagcoin.org/files/default-coin.svg" />
                    <img id="first-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="second-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="third-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="right0-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="right1-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                    <img id="right2-coin" src="https://downloads.dagcoin.org/files/coin.svg" />
                  </div>
                </div>
              </div>
              <div className="content-text order2">
                <div className="content-wrap">
                  <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_do_grow }} />
                  <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.how_to_desc_duplicate}} />
                  <a className="sign-up-btn" href="https://app.grow.dagcoin.org/sign-up">{data.wordpressPage.acf.start_growing}</a>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section id="safety" className="sections animate-safety">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <div className="content-img order2">
                <div className="lock-stack">
                  <div className="lock-wrapper">
                    <div className="locked"></div>
                    <img className="lock" src="https://downloads.dagcoin.org/files/lock.svg" />
                  </div>
                </div>
              </div>
              <div className="content-text order1">
                <div className="content-wrap">
                  <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.piority_title }} />
                  <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.piority_desc_duplicate }} />
                  <a className="sign-up-btn" href="https://app.grow.dagcoin.org/sign-up">{data.wordpressPage.acf.sign_up}</a>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section id="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.frequently_asked_questions }} />
              <div className="faq-content">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        {data.wordpressPage.acf.faq_title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        {data.wordpressPage.acf.faq_title_1}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_1}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        {data.wordpressPage.acf.faq_title_2}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_2}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        {data.wordpressPage.acf.faq_title_3}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_3}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>


                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        {data.wordpressPage.acf.faq_title_4}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_4}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        {data.wordpressPage.acf.faq_title_5}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_5}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        {data.wordpressPage.acf.faq_title_6}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_6}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="8">
                        {data.wordpressPage.acf.faq_title_7}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_7}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="9">
                        {data.wordpressPage.acf.faq_title_8}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_8}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="10">
                        {data.wordpressPage.acf.faq_title_9}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="10">
                      <Card.Body><p>{data.wordpressPage.acf.faq_content_9}</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>


              </div>

              <p className="support-link" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.didnt_find_an_answer_go_to_support_page }} />
            </div>
          </div>
        </div>
      </section>

      <section className="partone lastpart">
        <div className="container">
          <Row>
            <div className="col-lg-12">
              <h2>{data.wordpressPage.acf.time_to_start}</h2>
              <ul>
                <li>
                  <Img fluid={data.imagefour.childImageSharp.fluid} />
                  <p className="title-list">{data.wordpressPage.acf.time_to_start_1_title}</p>
                  <p>{data.wordpressPage.acf.time_to_start_1_desc}</p>
                </li>
                <li>
                  <Img fluid={data.imagefive.childImageSharp.fluid} />
                  <p className="title-list">{data.wordpressPage.acf.time_to_start_2_title}</p>
                  <p>{data.wordpressPage.acf.time_to_start_2_desc}</p>
                </li>
                <li>
                  <Img fluid={data.imagesix.childImageSharp.fluid} />
                  <p className="title-list">{data.wordpressPage.acf.time_to_start_3_title}</p>
                  <p>{data.wordpressPage.acf.time_to_start_3_desc}</p>
                </li>
              </ul>
              <a className="sign-up-btn" href="https://app.grow.dagcoin.org/sign-up">{data.wordpressPage.acf.start_growing}</a></div>
          </Row>
        </div>
      </section>

    </Layout>
  )
}
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      amount_to_deposit
      duration
      months
      initial_deposit
      period
      bonus_rate
      youll_get
      faq_content
      faq_content_1
      didnt_find_an_answer_go_to_support_page
      faq_title_3
      faq_content_3
      faq_content_9
      faq_content_2
      faq_content_4
      faq_content_5
      faq_content_6
      faq_content_7
      faq_content_8
      faq_title
      faq_title_1
      faq_title_2
      faq_title_4
      faq_title_5
      faq_title_6
      faq_title_7
      faq_title_9
      faq_title_8
      frequently_asked_questions
      grow_tile_duplicate
      home_desc
      home_title
      how_1_desc
      how_1_title
      how_2_desc
      how_3_desc
      how_2_title
      how_3_title
      how_do_grow
      how_it_works
      how_to_desc_duplicate
      piority_desc_duplicate
      piority_title
      sign_up
      start_growing
      start_now
      time_to_start
      time_to_start_1_desc
      time_to_start_1_title
      time_to_start_2_desc
      time_to_start_2_title
      time_to_start_3_desc
      time_to_start_3_title
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 

    imageone: file(relativePath: { eq: "image1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    imagetwo: file(relativePath: { eq: "image2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagethree: file(relativePath: { eq: "image3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagefour: file(relativePath: { eq: "image4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagefive: file(relativePath: { eq: "image5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagesix: file(relativePath: { eq: "image6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`
export default HomepageTemplate